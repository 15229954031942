import React from "react"

import Layout from "../components/layout"
import Seamless from "../components/SeamlessIFrame"
import ImageMeta from "../components/ImageMeta"

import ImageSlider from "../components/ImageSlider/ImageSlider"

import { SharedStateProvider } from "../components/SharedState/PageSharedState"

import SEO from "../components/seo"

const Veterans = () => {
  let language = "en"

  const images = [
    "SDOS/DEV/vetarans-2021-3",
    "SDOS/DEV/vetarans-2021-4",
    "SDOS/DEV/vetarans-2021-6",
    "SDOS/DEV/vetarans-2021-7"
  ]

  return (
    <SharedStateProvider>
      <Layout pageTitle="procedure-page" language={language}>
        <SEO
          lang="en"
          title="Learn about our Smiles For Veterans program"
          description="Follow the Smiles For Veterans steps to apply"
        />

        <div className="joshua-tree-content program-page-veterans">
          <div className="veterans-logo">
            <img src="https://res.cloudinary.com/nuvolum/image/upload/v1600816937/Programs/smiles_for_veterans.jpg" />
          </div>

          <div className="veterans-banner">
            <h2>Helping a Veteran Smile Again</h2>
            <p>
              Everyone deserves to smile, especially our veterans. Drs.
              Koshgerian and Shelton are proud to launch their Smiles For
              Veterans program, which provides the opportunity for one veteran
              living in the San Diego area to receive a{" "}
              <a
                href="/oral-surgery-procedures/dental-implants-san-diego-ca/full-arch-restoration/"
                title="About Full-arch restoration treatment"
              >
                full-arch restoration
              </a>{" "}
              procedure for free. This year’s deserving recipient is Leonard,
              71, from Chula Vista.
            </p>

            <p>
              Leonard is a US Army veteran who came to the United States after
              being born in the Philippines. His dental issues started when he
              was about 10 years old after another kid hit his head so hard that
              his teeth became loose. Now, missing nearly all of his teeth,
              Leonard rarely feels like smiling and is very aware of what other
              people see. In his application, Leonard wrote that he wants this
              smile makeover so that he can improve his self-confidence and chew
              healthy foods without difficulty.
            </p>

            <p>
              We look forward to improving Leonard’s oral health and confidence.
            </p>
          </div>

          <div className="details-grid">
            <div>
              <h3>About Full-Arch Restoration</h3>
              <p>
                Full-arch restoration is an innovative treatment designed to
                restore oral health and function in patients with severe tooth
                loss or decay. The recipient of the Smiles For Veterans program
                will receive this revolutionary procedure free of charge and
                experience a fully functioning and natural-looking smile.
              </p>

              <h5>Treatment Partners:</h5>
              <ul>
                <li>
                  <p>
                    Restorative Partner:{" "}
                    <a
                      href="https://www.edmundwjaydds.com/"
                      title="Learn more about Edmund W. Jay DDS, Inc. Prosthodontic & Implant Specialist"
                      target="_blank"
                    >
                      Edmund W. Jay DDS, Inc. Prosthodontic & Implant Specialist
                    </a>
                  </p>
                </li>
                <li>
                  <p>
                    <a
                      href="https://www.nashdentallab.com/"
                      title="Learn more about Nash Dental Lab"
                      target="_blank"
                    >
                      Nash Dental Lab
                    </a>
                  </p>
                </li>
                <li>
                  <p>Dr. Keith Reid</p>
                </li>
                <li>
                  <p>
                    <a
                      href="https://www.facebook.com/straumannsdimplantsolutions/?ref=page_internal"
                      title="Learn more about Rita Crawford, Dentist & Dental Offices"
                      target="_blank"
                    >
                      Rita Crawford
                    </a>
                    {" from "}
                    <a
                      href="https://www.straumann.com/group/us/en/patients.html"
                      title="Learn more about Straumann"
                      target="_blank"
                    >
                      Straumann
                    </a>
                  </p>
                </li>
              </ul>
            </div>

            {/* <div className="how-to-apply">
                    <img src="https://res.cloudinary.com/nuvolum/image/upload/v1597096865/Programs/smiles-for-soldiers-stars-blue.svg" alt="3 stars"/>
                    <p>Thank you for your interest in the Smiles For Veterans program. The application period is now closed. Applicants will be notified once the selection process has been completed.</p>
                    <h5>To Apply:</h5>
                    <ul>
                        <li>Be a veteran living in or near San Diego.</li>
                        <li>Fill out the application completely.</li>
                        <li>Provide clear, digital photos of your teeth and smile.</li>
                        <li>Submit completed applications now through October 22, 2020.</li>
                    </ul>
                </div> */}
          </div>

          {/* <div class="apply-now-banner">
                <p>
                    <img class="soldiers-stars" src="https://res.cloudinary.com/nuvolum/image/upload/v1596843154/Programs/smiles-for-soldiers-stars.svg"/>
                </p>
                <h2>APPLY NOW!</h2>
                <p>
                    <img class="soldiers-stars" src="https://res.cloudinary.com/nuvolum/image/upload/v1596843154/Programs/smiles-for-soldiers-stars.svg" />
                </p>
            </div>

            <div className="form-container">
                <div></div>
                <Seamless src="https://secureform.seamlessdocs.com/f/39771f7267530ab5b0fdf95757b25cdc?embedded=true" />
                <div></div>
            </div> */}

          <div className="stay-tuned">
            <p>
              Stay tuned to this page,{" "}
              <a
                href="https://www.facebook.com/SanDiegoOralSurgery"
                title="Follow us on Facebook"
                target="_blank"
              >
                Facebook
              </a>
              , and{" "}
              <a
                href="https://www.instagram.com/sandiegooralsurgery/"
                title="Follow us on Instagram"
                target="_blank"
              >
                Instagram
              </a>{" "}
              for updates on Leonard’s treatment journey!
            </p>

            <p>
              <i>
                Learn more about{" "}
                <a
                  href="/oral-surgery-procedures/dental-implants-san-diego-ca/"
                  title="Learn more about dental implants"
                >
                  dental implants
                </a>{" "}
                and{" "}
                <a
                  href="/oral-surgery-procedures/dental-implants-san-diego-ca/full-arch-restoration/"
                  title="Learn more about full-arch restoration"
                >
                  full-arch restoration
                </a>{" "}
                to see how they can benefit your smile. Whether you need one
                tooth or an entire arch of teeth replaced, our team has a
                solution for you.
              </i>
            </p>

            <ImageSlider images={images} useDots useArrows adaptiveHeight />
          </div>
        </div>
      </Layout>
    </SharedStateProvider>
  )
}

export default Veterans
